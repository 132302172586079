import React, { useState, useContext } from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Select } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import AuthContext from '../AuthContext';

function DozentIn2Kurs() {
  const [dozentInnen, setDozentInnen] = useState([]);
  const [kurse, setKurse] = useState([]);
  const [selectedDozentIn, setSelectedDozentIn] = useState('');
  const [selectedKurs, setSelectedKurs] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState('');
  const [errorDialog, setErrorDialog] = useState(false);
  const { token } = useContext(AuthContext); // Zugriff auf das JWT-Token

  const fetchDozentInnen = () => {
    axios.get('/api/getDozentInnen', {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(response => setDozentInnen(response.data))
      .catch(error => console.error('Error fetching DozentInnen:', error));
  };

  const fetchKurse = () => {
    axios.get('/api/getKurse', {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(response => setKurse(response.data))
      .catch(error => console.error('Error fetching Kurse:', error));
  };

  const handleDozentInChange = (event) => setSelectedDozentIn(event.target.value);
  const handleKursChange = (event) => setSelectedKurs(event.target.value);
  const handleAddDozentInToKurs = () => setOpenDialog(true);

  const handleConfirm = () => {
    axios.post('/api/addDozentInToKurs', { kurs_id: selectedKurs, user_id: selectedDozentIn }, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(response => {
        setMessage(response.data.message);
        setSelectedDozentIn('');
        setSelectedKurs('');
        setOpenDialog(false);
      })
      .catch(error => {
        setMessage(error.response?.data?.message || 'Ein Fehler ist aufgetreten.');
        setErrorDialog(true);
      });
  };

  return (
    <Container maxWidth="sm" className="verwaltung-container">
      <Accordion className="verwaltung-item">
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="h6" className="accordion-header">Dozent*in einem Kurs zuweisen</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Select
            fullWidth
            value={selectedDozentIn}
            onChange={handleDozentInChange}
            onOpen={fetchDozentInnen}
            displayEmpty
            className="input-field"
            style={{ backgroundColor: 'var(--custom-white)', color: 'var(--text-color)' }}
          >
            <MenuItem value="" disabled>
              Dozent*in auswählen
            </MenuItem>
            {dozentInnen.map((dozentIn) => (
              <MenuItem key={dozentIn.user_id} value={dozentIn.user_id}>
                {dozentIn.username} ({dozentIn.first_name} {dozentIn.last_name})
              </MenuItem>
            ))}
          </Select>

          <Select
            fullWidth
            value={selectedKurs}
            onChange={handleKursChange}
            onOpen={fetchKurse}
            displayEmpty
            className="input-field"
            style={{ backgroundColor: 'var(--custom-white)', color: 'var(--text-color)' }}
          >
            <MenuItem value="" disabled>
              Kurs auswählen
            </MenuItem>
            {kurse.map((kurs) => (
              <MenuItem key={kurs.kurs_id} value={kurs.kurs_id}>
                {kurs.name}
              </MenuItem>
            ))}
          </Select>

          <Button
            variant="contained"
            onClick={handleAddDozentInToKurs}
            className="accept-btn"
            style={{
              marginTop: '10px',
              height: '48px',
              backgroundColor: 'var(--accent-color)',  // Hintergrundfarbe
              color: 'var(--text-color)',             // Schriftfarbe
              fontWeight: 'bold'
            }}
          >
            Hinzufügen
          </Button>
        </AccordionDetails>
      </Accordion>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Bestätigung</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Möchten Sie den/die Dozent*in dem Kurs zuweisen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} style={{ backgroundColor: 'var(--warning-color)', color: 'var(--custom-white)', fontWeight: 'bold' }}>
            Abbrechen
          </Button>
          <Button onClick={handleConfirm} style={{ backgroundColor: 'var(--accept-color)', color: 'var(--custom-white)', fontWeight: 'bold' }}>
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={errorDialog} onClose={() => setErrorDialog(false)}>
        <DialogTitle>Fehler</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorDialog(false)} style={{ backgroundColor: 'var(--accept-color)', color: 'var(--custom-white)', fontWeight: 'bold' }}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default DozentIn2Kurs;
