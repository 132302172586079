import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);  // Standardmäßig ist kein Benutzer eingeloggt
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
      setLoading(false);  // Ladeindikator wird gestoppt, wenn ein gespeicherter Benutzer gefunden wird
    } else {
      checkUserStatus();  // Überprüfe, ob ein Benutzer eingeloggt ist
    }
  }, []);

  const checkUserStatus = async () => {
    try {
      const response = await axios.get('https://doctrina-mobilis.de/api/user', {
        withCredentials: true,
      });
      if (response.status === 200) {
        setUser(response.data);
        localStorage.setItem('user', JSON.stringify(response.data));
      } else {
        handleTokenExpiration();
      }
    } catch (error) {
      if (error.response?.status === 401) {
        handleTokenExpiration();
      }
      console.error('Error checking user:', error);
      setUser(null);
    } finally {
      setLoading(false);
    }
  };

  const handleTokenExpiration = () => {
    setUser(null);
    localStorage.removeItem('user');
    window.location.href = '/'; // Force redirect to login
  };

  // Optional: Periodischer Check
  useEffect(() => {
    if (user) {
      const tokenCheck = setInterval(async () => {
        console.log('Checking token...');
        try {
          const response = await axios.get('https://doctrina-mobilis.de/api/user', {
            withCredentials: true,
          });
          console.log('Token check response:', response);
  
          if (response.status === 200 || response.status === 304) {
            // Token ist gültig oder Ressource nicht geändert
            console.log('Token valid or resource not modified');
          } else {
            handleTokenExpiration();
          }
        } catch (error) {
          if (error.response?.status === 401 || error.response?.status === 403) {
            handleTokenExpiration();
          } else {
            console.error('Error checking user:', error);
          }
        }
      }, 60000);
  
      return () => clearInterval(tokenCheck);
    }
  }, [user]);

  const login = (userData) => {
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
  };

  const logout = () => {
    return new Promise((resolve, reject) => {
      setUser(null);
      localStorage.removeItem('user');
  
      // Lösche den Token-Cookie
      document.cookie = 'token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Secure; SameSite=Strict;';
  
      axios.post('https://doctrina-mobilis.de/api/logout', {}, { withCredentials: true })
        .then(() => {
          console.log('Logout successful');
          resolve();  // Erfolgreicher Logout
        })
        .catch(error => {
          console.error('Error during logout:', error);
          reject(error);  // Fehler beim Logout
        });
    });
  };
  
  return (
    <AuthContext.Provider value={{ user, setUser, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthContext;
