import React from 'react';
import { Typography } from '@mui/material';
import KursAnlegen from './reusable_modules/KursAnlegen'; // Importiere das Kurs-Anlegen-Modul
import './AdminPage.css'; // Falls du spezifische Styles für die Admin-Seite brauchst
import DozentInHinzufuegen from './reusable_modules/DozentInHinzufuegen';
import DozentInEntfernen from './reusable_modules/DozentInEntfernen';
import KursEntfernen from './reusable_modules/KursEntfernen';
import UserEntfernen from './reusable_modules/UserEntfernen';
import DozentIn2Kurs from './reusable_modules/DozentIn2Kurs';
import DozentIn2KursEntfernen from './reusable_modules/DozentIn2KursEntfernen';
import User2Kurs from './reusable_modules/User2Kurs';
import User2KursEntfernen from './reusable_modules/User2KursEntfernen';
import KurseListe from './reusable_modules/KurseListe';
import Kontaktanfragen from './reusable_modules/Kontaktanfragen'

function AdminPage() {
  return (
    <div className="admin-container">
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      <div className="admin-header" style={{ padding: '64px 0', textAlign: 'center' }}>
        <Typography variant="h2" component="h1" gutterBottom>
          Administrationsbereich
        </Typography>
        <Typography variant="h5" component="p" gutterBottom>
          Verwalten Sie Ihre Kurse und Inhalte.
        </Typography>
      </div>

      <div className="admin-list">
        <KurseListe />
        <Kontaktanfragen />
        <KursAnlegen />
        <KursEntfernen />
        <DozentInHinzufuegen />
        <DozentInEntfernen />
        <DozentIn2Kurs />
        <DozentIn2KursEntfernen />
        <User2Kurs />
        <User2KursEntfernen />
        <UserEntfernen />
        
        
        
      </div>
    </div>
  );
}

export default AdminPage;
