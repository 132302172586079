import React, { useState, useContext } from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Select } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import AuthContext from '../AuthContext';

function UserZuKursEntfernen() {
  const [kurse, setKurse] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedKurs, setSelectedKurs] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState('');
  const [errorDialog, setErrorDialog] = useState(false);

  const { token } = useContext(AuthContext);

  // Funktion, um Kurse zu laden
  const fetchKurse = () => {
    axios.get('/api/getKurse', {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(response => setKurse(response.data))
      .catch(error => console.error('Fehler beim Abrufen der Kurse:', error));
  };

  // Funktion, um die User eines Kurses zu laden
  const fetchUsersForKurs = (kursId) => {
    axios.get(`/api/getUsersForKurs/${kursId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(response => setUsers(response.data))
      .catch(error => console.error('Fehler beim Abrufen der User für den Kurs:', error));
  };

  // Handler für die Kursauswahl
  const handleKursChange = (event) => {
    const kursId = event.target.value;
    setSelectedKurs(kursId);
    fetchUsersForKurs(kursId); // User für den ausgewählten Kurs laden
  };

  // Handler für die Auswahl des Users
  const handleUserChange = (event) => setSelectedUser(event.target.value);

  // Öffnet den Bestätigungsdialog
  const handleRemoveUserFromKurs = () => setOpenDialog(true);

  // Bestätigung zum Entfernen des Users aus dem Kurs
  const handleConfirm = () => {
    axios.post('/api/removeUserFromKurs', { kurs_id: selectedKurs, user_id: selectedUser }, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(response => {
        setMessage(response.data.message);
        setSelectedKurs('');
        setSelectedUser('');
        setOpenDialog(false);
      })
      .catch(error => {
        setMessage(error.response?.data?.message || 'Ein Fehler ist aufgetreten.');
        setErrorDialog(true);
      });
  };

  return (
    <Container maxWidth="sm" className="verwaltung-container">
      <Accordion className="verwaltung-item">
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="h6" className="accordion-header">Benutzer aus einem Kurs entfernen</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* Dropdown zur Auswahl des Kurses */}
          <Select
            fullWidth
            value={selectedKurs}
            onChange={handleKursChange}
            onOpen={fetchKurse}
            displayEmpty
            className="input-field"
            style={{ backgroundColor: 'var(--custom-white)', color: 'var(--text-color)' }}
          >
            <MenuItem value="" disabled>Kurs auswählen</MenuItem>
            {kurse.map((kurs) => (
              <MenuItem key={kurs.kurs_id} value={kurs.kurs_id}>{kurs.name}</MenuItem>
            ))}
          </Select>

          {/* Dropdown zur Auswahl des Users */}
          <Select
            fullWidth
            value={selectedUser}
            onChange={handleUserChange}
            displayEmpty
            className="input-field"
            disabled={!selectedKurs}
            style={{ backgroundColor: selectedKurs ? 'var(--custom-white)' : 'lightgrey', color: 'var(--text-color)' }}
          >
            <MenuItem value="" disabled>Benutzer auswählen</MenuItem>
            {users.map((user) => (
              <MenuItem key={user.user_id} value={user.user_id}>
                {user.username} ({user.first_name} {user.last_name})
              </MenuItem>
            ))}
          </Select>

          {/* Button zum Entfernen des Users */}
          <Button
            variant="contained"
            onClick={handleRemoveUserFromKurs}
            className="accept-btn"
            disabled={!selectedUser}
            style={{
              marginTop: '10px',
              height: '48px',
              backgroundColor: 'var(--warning-color)',  // Hintergrundfarbe
              color: 'var(--custom-white)',            // Schriftfarbe
              fontWeight: 'bold'
            }}
          >
            Entfernen
          </Button>
        </AccordionDetails>
      </Accordion>

      {/* Bestätigungsdialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Bestätigung</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Möchten Sie den Benutzer wirklich aus dem Kurs entfernen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} style={{ backgroundColor: 'var(--warning-color)', color: 'var(--custom-white)', fontWeight: 'bold' }}>
            Abbrechen
          </Button>
          <Button onClick={handleConfirm} style={{ backgroundColor: 'var(--accept-color)', color: 'var(--custom-white)', fontWeight: 'bold' }}>
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>

      {/* Fehlerdialog */}
      <Dialog open={errorDialog} onClose={() => setErrorDialog(false)}>
        <DialogTitle>Fehler</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorDialog(false)} style={{ backgroundColor: 'var(--accept-color)', color: 'var(--custom-white)', fontWeight: 'bold' }}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default UserZuKursEntfernen;
