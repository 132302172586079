import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Container  // Hinzugefügter Import für Container
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TimePicker from 'react-time-picker';
import dayjs from 'dayjs'; // Date handling
import { useParams, useNavigate } from 'react-router-dom'; // Hinzugefügter Import für useParams und useNavigate
import axios from 'axios'; // Hinzugefügter Import für axios
import AuthContext from '../AuthContext'; // Authentication context
import './Kursuebersicht.css';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';



function Kursuebersicht() {
  const { kursId } = useParams();
  const [kurs, setKurs] = useState(null);
  const [titelseite, setTitelseite] = useState(null);
  const [termine, setTermine] = useState([]);
  const [error, setError] = useState(null);
  const [selectedTermin, setSelectedTermin] = useState(null); // Definiere selectedTermin
  const [open, setOpen] = useState(false); // Definiere open für den Dialog
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [teilnehmerInnen, setTeilnehmerInnen] = useState([]);
  const [dozierende, setDozierende] = useState([]);

  const [newTermin, setNewTermin] = useState({
    bezeichnung: '',
    ort: '',
    datum: null,
    uhrzeit: '12:00',
  });
  const [openCreateDialog, setOpenCreateDialog] = useState(false); // Controls the "Termin erstellen" dialog
  
  const [kapitelList, setKapitelList] = useState([]); // Definiere den State für die Kapitel-Liste
  const [kapitelOpen, setKapitelOpen] = useState(false);
  const [newKapitel, setNewKapitel] = useState('');

  useEffect(() => {
    const fetchKursData = async () => {
      try {
        // Kurs und Titelseite abrufen
        const kursResponse = await axios.get(`/api/kursTitelseite/${kursId}`);
        const { kurs, titelseite } = kursResponse.data;
        setKurs(kurs);
        setTitelseite(titelseite);
  
        // Kapitel abrufen
        const kapitelResponse = await axios.get(`/api/kapitel/${kursId}`);
        setKapitelList(kapitelResponse.data);
  
        // Termine abrufen
        const termineResponse = await axios.get(`/api/termine/${kursId}`);
        setTermine(termineResponse.data);
  
        // Teilnehmer abrufen
        const teilnehmerResponse = await axios.get(`/api/getUsersForKurs/${kursId}`, {
          headers: { Authorization: `Bearer ${user.token}` },
        });
  
        // Für jeden Teilnehmer die vollständigen Daten abrufen
        const teilnehmerDetails = await Promise.all(
          teilnehmerResponse.data.map(async (user) => {
            const userResponse = await axios.get(`/api/userDetails/${user.user_id}`, {
              headers: { Authorization: `Bearer ${user.token}` },
            });
            return userResponse.data;
          })
        );
        setTeilnehmerInnen(teilnehmerDetails);
  
        // Dozierende abrufen
        const dozierendeResponse = await axios.get(`/api/getDozentInnenForKurs/${kursId}`, {
          headers: { Authorization: `Bearer ${user.token}` },
        });
  
        // Für jeden Dozenten die vollständigen Daten abrufen
        const dozierendeDetails = await Promise.all(
          dozierendeResponse.data.map(async (user) => {
            const userResponse = await axios.get(`/api/userDetails/${user.user_id}`, {
              headers: { Authorization: `Bearer ${user.token}` },
            });
            return userResponse.data;
          })
        );
        setDozierende(dozierendeDetails);
  
      } catch (error) {
        console.error('Fehler beim Abrufen der Daten:', error);
        setError('Ein Fehler ist beim Abrufen der Daten aufgetreten.');
      }
    };
  
    fetchKursData();
  }, [kursId, user.token]);
  
  
  if (!kursId) {
    return <div>Keine Kurs-ID oder Kursname vorhanden. Bitte wählen Sie einen Kurs.</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!kurs) return <div>Lade...</div>;

  const canEdit = () => {
    return user && (user.role === 'admin' || kurs.instructor_id === user.id);
  };


  const handleOpenDialog = (termin) => {
    setSelectedTermin(termin);
    setOpen(true);
  };
  

  const handleCloseDialog = () => {
    setSelectedTermin(null);
    setOpen(false);
  };

  const handleDeleteTermin = async () => {
    try {
      const confirmDelete = window.confirm('Möchten Sie diesen Termin wirklich löschen?');
      if (confirmDelete) {
        await axios.delete(`/api/deleteTermin/${selectedTermin.id}`, {
          headers: { Authorization: `Bearer ${user.token}` },
        });
        setTermine(termine.filter((termin) => termin.id !== selectedTermin.id));
        setOpen(false);
        console.log('Termin erfolgreich gelöscht');
      }
    } catch (error) {
      console.error('Fehler beim Löschen des Termins:', error);
    }
  };
  

  const handleUpdateTermin = async () => {
    try {
      await axios.put(`/api/updateTermin/${selectedTermin.id}`, {
        datum: selectedTermin.datum,
        uhrzeit: selectedTermin.uhrzeit,
        ort: selectedTermin.ort,
        bezeichnung: selectedTermin.bezeichnung,
      });
      console.log('Termin aktualisiert');
      setOpen(false); // Close dialog
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Termins:', error);
    }
  };
  
  const handleOpenCreateDialog = () => {
    setOpenCreateDialog(true);
  };
  
  const handleCloseCreateDialog = () => {
    setNewTermin({
      bezeichnung: '',
      ort: '',
      datum: null,
      uhrzeit: '12:00',
    });
    setOpenCreateDialog(false);
  };
    
  const handleSaveNewTermin = async () => {
    if (newTermin.bezeichnung && newTermin.ort && newTermin.datum && newTermin.uhrzeit) {
      try {
        const response = await axios.post(`/api/createTermin/${kursId}`, newTermin, {
          headers: { Authorization: `Bearer ${user.token}` },
        });
        setTermine([...termine, response.data]);
        handleCloseCreateDialog();
        console.log('Neuer Termin erfolgreich erstellt');
      } catch (error) {
        console.error('Fehler beim Erstellen des neuen Termins:', error);
      }
    } else {
      alert('Bitte alle Felder ausfüllen');
    }
  };

  const handleKapitelOpen = () => setKapitelOpen(true);
  const handleKapitelClose = () => setKapitelOpen(false);

  const handleKapitelSave = async () => {
    try {
      // Erstelle ein neues Kapitel
      const kapitelResponse = await axios.post(`/api/kapitel`, {
        name: newKapitel,
        kurs_id: kursId,
        position: kapitelList.length + 1, // Setzt die Position für das neue Kapitel
      }, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
  
      const newKapitelId = kapitelResponse.data.kapitel_id; // Hole die kapitel_id des neu erstellten Kapitels
  
      // Erstelle ein KapitelText-Eintrag
      const textResponse = await axios.post(`/api/kapitelText`, {
        kapitel_id: newKapitelId,
        text: "Neuer Kapiteltext", // Standardtext oder benutzerdefiniert
      }, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
  
      const newTextId = textResponse.data.text_id;
  
      // Verknüpfe den Text mit dem Kapitel in der KapitelInhalt-Tabelle
      await axios.post(`/api/kapitelInhalt`, {
        kapitel_id: newKapitelId,
        inhalt_typ: 'Text',
        inhalt_id: newTextId,
        position: 1, // Setzt die Position des Textinhalts im Kapitel
      }, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
  
      setKapitelList([...kapitelList, kapitelResponse.data]); // Füge das neue Kapitel zur Liste hinzu
      setNewKapitel('');
      handleKapitelClose();
    } catch (error) {
      console.error('Fehler beim Erstellen des Kapitels:', error);
    }
  };
  

  // Funktion zum Umgang mit Drag-and-Drop-Ereignissen
const handleOnDragEnd = (result) => {
    const { destination, source } = result;
  
    if (!destination) return;
  
    const reorderedChapters = Array.from(kapitelList);
    const [movedItem] = reorderedChapters.splice(source.index, 1);
    reorderedChapters.splice(destination.index, 0, movedItem);
  
    setKapitelList(reorderedChapters);
  
    // Hier könntest du eine API-Anfrage senden, um die Reihenfolge in der Datenbank zu aktualisieren
    axios.put(`/api/updateKapitelPosition`, { kapitel: reorderedChapters });
  };

  const deleteKapitel = async (kapitelId) => {
    try {
      await axios.delete(`/api/deleteKapitel/${kapitelId}`);
      setKapitelList(kapitelList.filter((k) => k.id !== kapitelId));
    } catch (error) {
      console.error('Fehler beim Löschen des Kapitels:', error);
    }
  };

  const renderUserTile = (user) => (
    <Box key={user.user_id} sx={{ border: '1px solid #ccc', padding: '10px', borderRadius: '5px', marginBottom: '10px', backgroundColor: 'var(--accent-color)' }}>
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: 'var(--text-color)' }}>Name: {`${user.first_name} ${user.last_name}`}</Typography>
      <Typography variant="body2" sx={{ color: 'var(--text-color)' }}>Username: {user.username}</Typography>
      <Typography variant="body2" sx={{ color: 'var(--text-color)' }}>Email: {user.email}</Typography>
    </Box>
  );
  
  

  return (

    <Container>
      <Typography
        variant="h1"
        gutterBottom
        sx={{
          marginTop: '20px',
          fontWeight: 'bold',
          fontSize: { xs: '2rem', sm: '3rem', md: '4rem', lg: '5rem' }, // Responsive sizes
        }}
      >
        Kursübersicht<br />{kurs.name}
      </Typography>

      {/* Course Leader */}
      <Typography variant="h6" gutterBottom sx={{ marginTop: '20px', fontWeight: 'bold' }}>
        Kursleitung: <span style={{ fontWeight: 'normal' }}>{titelseite.kursleiter}</span>
      </Typography>

      {/* Display the course image as a banner */}
      {titelseite.titelbild && (
        <Box
          component="img"
          src={`/api/get-image/${titelseite.titelbild}`}
          alt="Course Banner"
          sx={{
            width: '100%',
            maxHeight: '300px',
            objectFit: 'contain',
            objectPosition: 'center',
          }}
        />
      )}

      {/* Box Titelseite */}
      <Box
        sx={{
            display: 'block', // Alle Elemente sollen untereinander stehen
            backgroundColor: 'var(--primary-color)', // Box Hintergrund in Primary Color
            padding: '20px',
            border: '1px solid #ccc',
            marginBottom: '20px',
        }}
        >
        <Typography 
            variant="h6" 
            sx={{ color: 'var(--custom-white)' }} // Textfarbe in Custom White
        >
            Titelseite
        </Typography>
        
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '10px', marginTop: '10px' }}>
            <Button
            variant="outlined"
            onClick={() => navigate(`/titelseite/${kursId}`)}
            sx={{
                backgroundColor: 'var(--accent-color)', // Button Hintergrund in Accent Color
                color: 'var(--text-color)', // Textfarbe in Text Color
                marginRight: '10px',
                fontWeight: 'bold',
            }}
            >
            Anzeigen
            </Button>
            
            {canEdit() && (
            <Button
                variant="contained"
                onClick={() => navigate(`/kurs/${kursId}`)}
                sx={{
                backgroundColor: 'var(--accent-color)', // Button Hintergrund in Accent Color
                color: 'var(--text-color)', // Textfarbe in Text Color
                fontWeight: 'bold',
                }}
            >
                Bearbeiten
            </Button>
            )}
        </Box>
        </Box>

{/* Accordion Kursinhalte */}
<Accordion
  disableGutters
  sx={{
    marginBottom: '20px',
    backgroundColor: 'var(--primary-color)',
    border: '1px solid #ccc',
  }}
>
  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
  <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <Typography variant="h6" sx={{ color: 'var(--custom-white)' }}>
                    Kursinhalte
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '10px', marginTop: '10px' }}>
                    {canEdit() && (
                    <Button
                        variant="contained"
                        sx={{
                        backgroundColor: 'var(--accent-color)',
                        color: 'var(--text-color)',
                        fontWeight: 'bold',
                        }}
                        onClick={handleKapitelOpen}
                    >
                        Kapitel anlegen
                  </Button>
                  
                    )}
                </Box>
                </Box>
  </AccordionSummary>
  
  <AccordionDetails sx={{ backgroundColor: 'var(--secondary-color)' }}>
    <Typography>Kapitelübersicht</Typography>

    <DragDropContext onDragEnd={handleOnDragEnd}>
  <Droppable droppableId="kapitelListe">
    {(provided) => (
      <ul {...provided.droppableProps} ref={provided.innerRef}>
        {kapitelList.map((kapitel, index) => (
          <Draggable key={kapitel.kapitel_id} draggableId={kapitel.kapitel_id.toString()} index={index}>
            {(provided) => (
              <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '10px',
                    backgroundColor: 'var(--primary-color)',
                    marginBottom: '10px',
                  }}
                >
                  <Box className="kapitel-item">
                    <Typography variant="body1" className="kapitel-title">
                      {kapitel.name}
                    </Typography>

                    <Box className="kapitel-buttons">
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{ backgroundColor: 'var(--secondary-color)', color: 'black' }}
                        onClick={() => navigate(`/kapitel/${kapitel.kapitel_id}`)}
                      >
                        Ansehen
                      </Button>

                      <Button
                        variant="outlined"
                        size="small"
                        sx={{ backgroundColor: 'var(--accent-color)', color: 'black' }}
                        onClick={() => navigate(`/kapitelformular/${kapitel.kapitel_id}`)}
                      >
                        Bearbeiten
                      </Button>

                      <Button
                        variant="contained"
                        size="small"
                        sx={{ backgroundColor: 'var(--warning-color)', color: 'black' }}
                        onClick={() => deleteKapitel(kapitel.kapitel_id)}
                      >
                        Löschen
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </li>
            )}
          </Draggable>
        ))}
        {provided.placeholder}
      </ul>
    )}
  </Droppable>
</DragDropContext>

  </AccordionDetails>
</Accordion>

<Dialog open={kapitelOpen} onClose={handleKapitelClose}>
        <DialogTitle>Neues Kapitel anlegen</DialogTitle>
        <DialogContent>
          <TextField
            label="Kapitelname"
            fullWidth
            value={newKapitel}
            onChange={(e) => setNewKapitel(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleKapitelClose} sx={{ backgroundColor: 'var(--warning-color)', color: 'white' }}>
            Abbrechen
          </Button>
          <Button onClick={handleKapitelSave} sx={{ backgroundColor: 'var(--accept-color)', color: 'white' }}>
            Speichern
          </Button>
        </DialogActions>
      </Dialog>


            {/* Accordion Termine */}    

            <Accordion
            disableGutters
            sx={{
                marginBottom: '20px',
                backgroundColor: 'var(--primary-color)',
                border: '1px solid #ccc',
            }}
            >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <Typography variant="h6" sx={{ color: 'var(--custom-white)' }}>
                    Termine
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '10px', marginTop: '10px' }}>
                    {canEdit() && (
                    <Button
                        variant="contained"
                        sx={{
                        backgroundColor: 'var(--accent-color)',
                        color: 'var(--text-color)',
                        fontWeight: 'bold',
                        }}
                        onClick={handleOpenCreateDialog}
                    >
                        Termin erstellen
                  </Button>
                  
                    )}
                </Box>
                </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: 'var(--secondary-color)' }}>
                {termine.length > 0 ? (
                termine.map((termin) => (
                    <Box key={termin.id} className="termin-kachel" sx={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between' }}>
                    <div className="datum-bereich">
                        <div className="wochentag">{dayjs(termin.datum).format('dd').toUpperCase()}</div>
                        <div className="datum">{dayjs(termin.datum).format('DD.MM.YYYY')}</div>
                        <div className="uhrzeit">{termin.uhrzeit}</div>
                    </div>
                    <div className="kurs-bereich">
                        <div className="kursname">{kurs.name}</div>
                        <div className="bezeichnung">{termin.bezeichnung ? termin.bezeichnung : 'Keine Bezeichnung'}</div>
                        <div className="ort">{termin.ort}</div>
                    </div>
                    {canEdit() && (
                        <Button
                        variant="contained"
                        sx={{
                            backgroundColor: 'var(--accent-color)',
                            color: 'var(--text-color)',
                            fontWeight: 'bold',
                        }}
                        onClick={() => handleOpenDialog(termin)}
                        >
                        Termin bearbeiten
                        </Button>
                    )}
                    </Box>
                ))
                ) : (
                <Typography variant="body2">Keine Termine gefunden.</Typography>
                )}
            </AccordionDetails>
            </Accordion>

            <Dialog 
                open={open} 
                onClose={handleCloseDialog}
                maxWidth="md"  // Stellt sicher, dass der Dialog breiter ist
                maxHeight="md" 
                fullWidth  // Füllt die Breite des Bildschirms aus
                sx={{
                    '& .MuiDialog-paper': {
                    padding: '20px',
                    backgroundColor: 'var(--secondary-color)',
                    borderRadius: '10px',
                    },
                }}
                >
                <DialogTitle>Termin bearbeiten</DialogTitle>
                <DialogContent>
                    <TextField
                    label="Bezeichnung"
                    fullWidth
                    value={selectedTermin?.bezeichnung || ''}
                    onChange={(e) => setSelectedTermin({ ...selectedTermin, bezeichnung: e.target.value })}
                    sx={{ marginBottom: '10px', marginTop: '20px'}}
                    />
                    <TextField
                    label="Ort"
                    fullWidth
                    value={selectedTermin?.ort || ''}
                    onChange={(e) => setSelectedTermin({ ...selectedTermin, ort: e.target.value })}
                    sx={{ marginBottom: '10px' }}
                    />
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    Datum:
                </Typography>
                <DatePicker
                    selected={selectedTermin?.datum ? new Date(selectedTermin?.datum) : null}
                    onChange={(date) => setSelectedTermin({ ...selectedTermin, datum: date })}
                    dateFormat="yyyy-MM-dd"
                    customInput={<TextField fullWidth sx={{ marginBottom: '10px', marginTop: '10px' }} />}
                    placeholderText="Datum auswählen"
                />

                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    Uhrzeit:
                </Typography>
                <TimePicker
                    onChange={(time) => setSelectedTermin({ ...selectedTermin, uhrzeit: time })}
                    value={selectedTermin?.uhrzeit || '12:00'}
                    disableClock={true}
                    format="HH:mm"
                    className="custom-time-picker"
                    maxDetail="minute"  // Setzt den Detailgrad auf Minuten
                    sx={{
                    '& .react-time-picker__inputGroup': {
                        fontSize: '1.2rem',
                        display: 'flex',
                        gap: '5px',
                    },
                    '& .react-time-picker__inputGroup__input': {
                        width: '50px',
                        height: '40px',
                        fontWeight: 'bold',  // Fettschrift für das Eingabefeld
                    },
                    '& .react-time-picker__wrapper': {
                        border: '1px solid #ccc',
                        padding: '10px',
                        borderRadius: '8px',
                        backgroundColor: 'var(--primary-color)',
                    },
                    }}
                />
  </DialogContent>
  <DialogActions>
        <Button 
            onClick={handleCloseDialog} 
            sx={{ 
            backgroundColor: 'var(--warning-color)', 
            color: 'white',  // Optional: Textfarbe anpassen, damit sie auf dem Hintergrund sichtbar ist
            '&:hover': {
                backgroundColor: 'var(--warning-color-hover)',  // Optional: Hover-Farbe
            }
            }}>
            Abbrechen
        </Button>
        
        <Button 
            onClick={handleUpdateTermin} 
            variant="contained" 
            color="primary">
            Speichern
        </Button>
        
        <Button 
            onClick={handleDeleteTermin} 
            sx={{ 
            backgroundColor: 'var(--warning-color)', 
            color: 'white',  // Optional: Textfarbe anpassen
            '&:hover': {
                backgroundColor: 'var(--warning-color-hover)',  // Optional: Hover-Farbe
            }
            }}>
            Löschen
        </Button>
    </DialogActions>
</Dialog>

<Dialog
  open={openCreateDialog}
  onClose={handleCloseCreateDialog}
  maxWidth="md"
  fullWidth
  sx={{
    '& .MuiDialog-paper': {
      padding: '20px',
      backgroundColor: 'var(--secondary-color)',
      borderRadius: '10px',
    },
  }}
>
  <DialogTitle>Neuen Termin erstellen</DialogTitle>
  <DialogContent>
    <TextField
      label="Bezeichnung"
      fullWidth
      value={newTermin.bezeichnung}
      onChange={(e) => setNewTermin({ ...newTermin, bezeichnung: e.target.value })}
      sx={{ marginBottom: '10px', marginTop: '20px' }}
    />
    <TextField
      label="Ort"
      fullWidth
      value={newTermin.ort}
      onChange={(e) => setNewTermin({ ...newTermin, ort: e.target.value })}
      sx={{ marginBottom: '10px' }}
    />
    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
      Datum:
    </Typography>
    <DatePicker
      selected={newTermin.datum}
      onChange={(date) => setNewTermin({ ...newTermin, datum: date })}
      dateFormat="yyyy-MM-dd"
      customInput={<TextField fullWidth sx={{ marginBottom: '10px', marginTop: '10px' }} />}
      placeholderText="Datum auswählen"
    />
    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
      Uhrzeit:
    </Typography>
    <TimePicker
      onChange={(time) => setNewTermin({ ...newTermin, uhrzeit: time })}
      value={newTermin.uhrzeit}
      disableClock={true}
      format="HH:mm"
      className="custom-time-picker"
      maxDetail="minute"
      sx={{
        '& .react-time-picker__inputGroup': {
          fontSize: '1.2rem',
          display: 'flex',
          gap: '5px',
        },
        '& .react-time-picker__inputGroup__input': {
          width: '50px',
          height: '40px',
          fontWeight: 'bold',
        },
        '& .react-time-picker__wrapper': {
          border: '1px solid #ccc',
          padding: '10px',
          borderRadius: '8px',
          backgroundColor: 'var(--primary-color)',
        },
      }}
    />
  </DialogContent>
  <DialogActions>
    <Button 
      onClick={handleCloseCreateDialog} 
      sx={{ 
        backgroundColor: 'var(--warning-color)', 
        color: 'white',
      }}
    >
      Abbrechen
    </Button>
    <Button
      onClick={handleSaveNewTermin}
      sx={{
        backgroundColor: 'var(--accept-color)',
        color: 'white',
      }}
    >
      Speichern
    </Button>
  </DialogActions>
</Dialog>




    {/* Accordion für Kursteilnehmer*innen */}
    <Accordion disableGutters sx={{ marginBottom: '20px', backgroundColor: 'var(--primary-color)', border: '1px solid #ccc' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" sx={{ color: 'var(--custom-white)' }}>Kursteilnehmer*innen</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: 'var(--secondary-color)' }}>
          {teilnehmerInnen.length > 0 ? (
            teilnehmerInnen.map((user) => renderUserTile(user))
          ) : (
            <Typography>Keine Kursteilnehmer*innen verfügbar</Typography>
          )}
        </AccordionDetails>
      </Accordion>

      {/* Accordion für Dozierende */}
      <Accordion disableGutters sx={{ marginBottom: '20px', backgroundColor: 'var(--primary-color)', border: '1px solid #ccc' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" sx={{ color: 'var(--custom-white)' }}>Dozierendenliste</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: 'var(--secondary-color)' }}>
          {dozierende.length > 0 ? (
            dozierende.map((user) => renderUserTile(user))
          ) : (
            <Typography>Keine Dozierenden verfügbar</Typography>
          )}
        </AccordionDetails>
      </Accordion>



    </Container>
  );
}

export default Kursuebersicht;
